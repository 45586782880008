<template>

    <div>
        <v-divider></v-divider>

            <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
            <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>

            <div class="mr-6 mt-4">
                <hb-data-table-header
                    left-columns="10"
                    right-columns="2"
                >
                    <template v-slot:description>
                        Here you can create categories for your spaces. This allows you to group them however you want for searching within the application or displaying on your website. You can set a space's category from the Edit Space page.
                    </template>
                    <template v-slot:actions>
                        <hb-btn small color="secondary" @click="showAdd = true">Add Category</hb-btn>
                    </template>
                </hb-data-table-header>

                <hb-data-table
                    :headers="headers"
                    :items="categories"
                    @click:row="editItem"
                >
                    <template v-slot:item.info="{ item }">
                        <span class="font-weight-medium">{{ item.name }}</span><br />
                        <span class="hb-text-light">{{ item.description }}</span>
                    </template>
                    <template v-slot:item.attributes="{ item }">
                        <div v-for="(a, i) in item.Attributes" :key="'attribute' + i">
                            {{a.name}}: {{a.value}}
                        </div>
                    </template>
                    <template v-slot:item.count="{ item }">{{ item.Units.unit_count }}</template>
                    <template v-slot:item.vacant="{ item }">{{ item.Vacant.unit_count }}</template>
                    <template v-slot:item.price="{ item }">{{ item.price }}</template>
                    <template v-slot:item.actions="{ item }">
                        <hb-menu
                            options
                            align-right
                        >
                            <v-list>
                                <v-list-item @click="editItem(item)">
                                    <v-list-item-title>Edit</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="deleteItem(item)">
                                    <v-list-item-title>Delete</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </hb-menu>
                    </template>
                </hb-data-table>

            </div>



        <edit-category v-model="showAdd" v-if="showAdd" :selected="selected" @close="closeWindow" @refetch="fetchData">
            </edit-category>



        <edit-category v-model="showEdit" v-if="showEdit" :selected="selected" @close="closeWindow" @refetch="fetchData">
            </edit-category>

        <hb-modal size="medium" show-help-link title="Delete Space Category" v-model="showDelete" v-if="showDelete" @close="closeWindow" confirmation>
            <template v-slot:content>
                <div class="py-4 px-6">
                    Are you sure you want to delete the selected space category? All spaces in this category will be set to no category.
                    <br /><br />
                    This action cannot be undone.
                </div>
            </template>
            <template v-slot:actions>
                <hb-btn color="destructive" @click="deleteConfirm">Delete</hb-btn>
            </template>
        </hb-modal>

    </div>
</template>

<script type="text/babel">

    import EditCategory from './EditCategory.vue';
    import api from '../../assets/api.js';


    export default {
        name: "CategorySettings",
        data() {
            return {
                list: [],
                categories:[],
                selected: {},
                showAdd: false,
                showEdit: false,
                showDelete: false,
                headers: [
                    { text: "Category Info", value: "info" },
                    { text: "Attributes", value: "attributes" },
                    { text: "Count", value: "count" },
                    { text: "Vacant", value: "vacant" },
                    { text: "Price", value: "price" },
                    { text: "", value: "actions", align: "right", sortable: false, width: 10 }
                ],
            }
        },
        components:{

            EditCategory,

        },
        created(){
            this.fetchData();
        },
        computed:{
        },
        methods:{

            closeWindow(){
                this.showEdit = false;
                this.showDelete = false;
                this.showAdd = false;
                this.selected = {};
            },
            fetchData(){
                api.get(this, api.CATEGORIES).then(r => {
                    this.categories = r.categories;
                });
            },
            saveNewOrder(){
                var data = {categories: this.categories};
                api.put(this, api.CATEGORIES_SORT, data).then(r => {

                });


            },
            editItem(c){
                this.selected = c;
                this.showEdit = true;
            },
            deleteItem(c){
                this.selected = c;
                this.showDelete = true;
            },
            deleteConfirm(){

                api.delete(this, api.CATEGORIES, this.selected.id).then(r => {
                    this.showDelete = false;
                    this.fetchData();
                    this.selected = {};
                });

            },
        }
    }
</script>

<style>
    .subdued {
        color: #a5adb4;
        line-height: 18px;
    }
</style>
